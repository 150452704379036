import { AnalyticsProductDetailObjectException } from '../errors/analyticsError';
import Logger from '../utils/analyticsLogger';
import DataObject from './DataObject';
import ProductObject from './ProductObject';

export default class ProductDetailObject extends DataObject {
  constructor(data) {
    super('uaevent', data);

    this.category = 'Ecommerce';
    if (data.category) {
      Logger.warn(
        `[AnalyticsProductDetailObjectException] Category always defaults to "Ecommerce" (L'Oréal guideline), currently [${
          data.category
        }] was provided`,
        data,
        false
      );
    }

    this.action = 'Product Detail';
    if (data.action) {
      Logger.warn(
        `[AnalyticsProductDetailObjectException] Action always defaults to "Product Detail" (L'Oréal guideline), currently [${
          data.action
        }] was provided`,
        data,
        false
      );
    }

    this.action = 'Product Detail';
    if (data.label) {
      this.label = data.label;
    }

    if (!data.products || data.products.length === 0) {
      Logger.error('[AnalyticsProductDetailObjectException] Product(s) are required for a productDetail', data);
      throw new AnalyticsProductDetailObjectException('Product(s) are required for a productDetail');
    }
  }

  initEcommerce() {
    this.result.ecommerce = this.result.ecommerce || {};
  }

  set category(category) {
    this.result.eventCategory = this.setProperty(category, false);
  }

  set action(action) {
    this.result.eventAction = this.setProperty(action, false);
  }

  set label(label) {
    this.result.eventLabel = this.setProperty(label, false);
  }

  set value(value) {
    this.result.eventValue = this.setProperty(value);
  }

  set currency(currency) {
    this.initEcommerce();
    this.result.ecommerce.currencyCode = this.setProperty(currency).toUpperCase();
  }

  set products(products) {
    this.initEcommerce();
    this.result.ecommerce.detail = products.map(product => new ProductObject(product).toObject());
  }

  get products() {
    return (this.result.ecommerce && this.result.ecommerce.detail) || [];
  }
}
