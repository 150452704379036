export * from './AnalyticsHandler';
export * from './models';
export * from './directives/tag';
import { tag } from './directives/tag';

export default {
  directives: {
    tag
  }
};
