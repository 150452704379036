import UserActionEventObject from './UserActionEventObject';
import NonInteractiveEventObject from './NonInteractiveEventObject';
import ProductClickObject from './ProductClickObject';
import ProductImpressionObject from './ProductImpressionObject';
import ProductDetailObject from './ProductDetailObject';
import PromotionClickObject from './PromotionClickObject';
import PromotionImpressionObject from './PromotionImpressionObject';

/**
 * types
 * List of allowed data types
 * @type {object}
 * @member AnalyticsHandler#types
 */
export const TAGGING_TYPES = {
  productClick: ProductClickObject,
  productImpression: ProductImpressionObject,
  productDetail: ProductDetailObject,
  userActionEvent: UserActionEventObject,
  nonInteractiveEvent: NonInteractiveEventObject,
  promotionClick: PromotionClickObject,
  promotionImpression: PromotionImpressionObject
};

/**
 * Whitelist of types of Analytics events.
 */
export const TAGGING_TYPES_ARRAY = Object.keys(TAGGING_TYPES);

/**
 *
 * @param type
 * @returns {boolean}
 */
export const hasTaggingType = type => TAGGING_TYPES_ARRAY.map(o => o.toLowerCase()).indexOf(type.toLowerCase()) > -1;
