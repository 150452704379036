import { AnalyticsTagDirectiveException } from '../errors/analyticsError';
import { hasTaggingType, TAGGING_TYPES_ARRAY } from '../models/TaggingTypes';
import TagUtils from './tagUtils';

/**
 * Tag Directive
 * @module v-tag
 */

/**
 * Vue Tag directive declaration.
 */
export const tag = {
  name: 'tag',
  inserted: (el, binding) => {
    const { arg, value: data = {} } = binding;
    let eventType = 'click';

    if (!arg) {
      throw new AnalyticsTagDirectiveException(`No argument given to v-tag. Example usage: v-tag:event="{category: 'video', action: 'play', label: 'video.mp4'}}". Argument options: ${TAGGING_TYPES_ARRAY.join(', ').toLowerCase()}`);
    }

    if (!hasTaggingType(arg)) {
      throw new AnalyticsTagDirectiveException(`v-tag argument [${arg}] to v-tag is wrong. Argument options: ${TAGGING_TYPES_ARRAY.join(', ').toLowerCase()}`);
    }

    switch (arg) {
      case 'productimpression':
        data.type = 'productImpression';
        eventType = 'impression';
        break;

      case 'productdetail':
        data.type = 'productDetail';
        eventType = 'detail';
        break;

      case 'productclick':
        data.type = 'productClick';
        break;

      case 'promotionclick':
        data.type = 'promotionClick';
        break;

      case 'promotionimpression':
        data.type = 'promotionImpression';
        eventType = 'impression';
        break;

      case 'useractionevent':
      default:
        data.type = 'userActionEvent';
        break;
    }

    TagUtils.handleEvent(eventType, el, data, 'dom' in binding.modifiers);
  }
};
