import { debug } from '../../env/env';
import { intersectionViewportObserver } from '../../utilities/intersectionViewportObserver';
import { AnalyticsHandler } from '../AnalyticsHandler';

/**
 * Clean object with undefined value for each key
 * @param o
 * @returns {{}}
 */
const clean = o =>
  Object.keys(o).reduce((acc, key) => {
    if (o[key]) {
      acc[key] = o[key];
    }
    return acc;
  }, {});

export default {
  /**
   * Binds the event for the Google TagManager action to the DOM node. Click or Impression.
   * @param {String} type - Type of event. Can be click or impression.
   * @param {Node} node - Dom node to use for event handling.
   * @param {Object} data - Data send by the component who wants to send an analytics event.
   * @param {Boolean} takeFromDom - Search the Dom for additional data.
   */
  handleEvent(type, node, data, takeFromDom) {
    const onEvent = () => {
      const dataToSend = takeFromDom ? this.createDataObjectBasedOnDom(node.closest('[data-tag-wrapper]'), data) : data;

      try {
        AnalyticsHandler.getAnalyticsHandler().push(dataToSend);
      } catch (er) {
        if (debug) {
          node.setAttribute('data-error', er.message);
          node.classList.add('accessibilityError');
        }
        throw er;
      }
    };

    if (type === 'click') {
      node.addEventListener('click', onEvent);
    } else {
      intersectionViewportObserver(node).then(onEvent);
    }
  },

  /**
   * Get a data value from a Dom Node.
   * Looks for data tag on given Node first, if not found,
   * it does querySelector to find it in children.
   * @param {Node} domNode - Node to query for data tag
   * @param {String} selector - Query for selector
   * @param {String} field - Name of data tag to search. Eg: data-tag-product-id.
   */
  getValue(domNode, selector, field) {
    const value = domNode.getAttribute(`data-tag-${field}`);

    if (value && value !== '') {
      return value;
    }

    return this.getValueFromSelector(domNode.querySelector(selector), field);
  },

  /**
   * Extract value from a list of fields and return an object.
   * @param domNode
   * @param fields
   * @param cb
   */
  getTagValues(domNode, fields, cb = o => o) {
    return fields
      .filter(o => !!o)
      .map(key => ({
        key,
        value: this.getValue(domNode, `[data-tag-${key}]`, key)
      }))
      .reduce((acc, item) => {
        if (item.value !== undefined && item.value) {
          acc[cb(item.key)] = item.value;
        }
        return acc;
      }, {});
  },

  /**
   * Returns a the content of a data tag on a Dom node.
   * When node doesn't exist, it returns false.
   * If the data tag has no content, it returns the textContent of the Dom node.
   * @param {Node} domNode - Node to query for data tag
   * @param {String} field - Name of data tag to search. Eg: data-tag-product-id.
   */
  getValueFromSelector(domNode, field) {
    if (!domNode) {
      return false;
    }

    const value = domNode.getAttribute(`data-tag-${field}`);
    return value === '' ? domNode.textContent : value;
  },

  /**
   * Returns all data it can find in the dom for an analytics action.
   * @param {Node} node - Parent Node to query for data. Closest [data-tag-wrapper] to Node which triggered the event.
   * @param {Object} data - Base data send by the Node which triggered the event. This data will be merged with the data we find in the Dom.
   */
  createDataObjectBasedOnDom(node, data) {
    const dataFromDom = this.getTagValues(node, ['category', 'action', 'label']);

    if (data.type === 'productClick' || data.type === 'productImpression' || data.type === 'productDetail') {
      const productsNodes = node.querySelectorAll('[data-tag-product-identifier]');
      dataFromDom.products = [];

      for (const product of productsNodes) {
        dataFromDom.products.push(this.createDataForProduct(product, node, data, dataFromDom));
      }
    }

    if (data.type === 'promotionClick' || data.type === 'promotionImpression' || data.type === 'productDetail') {
      const promotionNodes = node.querySelectorAll('[data-tag-promotion-identifier]');
      dataFromDom.promotions = [];

      for (const promotion of promotionNodes) {
        dataFromDom.promotions.push(this.createDataForPromotion(promotion));
      }
    }

    return { ...data, ...dataFromDom };
  },

  /**
   *
   * @param product
   * @param node
   * @param data
   * @param dataFromDom
   */
  createDataForProduct(product, node, data, dataFromDom) {
    const currency = this.getValue(node, '[data-tag-product-currency]', 'product-currency');
    const name = this.getValue(product, '[data-tag-product-name]', 'product-name');
    const id = this.getValue(product, '[data-tag-product-id]', 'product-id');
    const category = this.getValue(product, '[data-tag-product-category]', 'product-category');
    const brand = this.getValue(product, '[data-tag-product-brand]', 'product-brand');
    const price = this.getValue(product, '[data-tag-product-price]', 'product-price');
    const variant = this.getValue(product, '[data-tag-product-variant]', 'product-variant');
    const position = this.getValue(product, '[data-tag-product-position]', 'product-position');
    const list = this.getValue(product, '[data-tag-product-list]', 'product-list');

    const dimension35 = this.getValue(product, '[data-tag-product-dimension35]', 'product-dimension35');
    const dimension36 = this.getValue(product, '[data-tag-product-dimension36]', 'product-dimension36');
    const dimension37 = this.getValue(product, '[data-tag-product-dimension37]', 'product-dimension37');
    const dimension38 = this.getValue(product, '[data-tag-product-dimension38]', 'product-dimension38');
    const dimension39 = this.getValue(product, '[data-tag-product-dimension39]', 'product-dimension39');
    const dimension40 = this.getValue(product, '[data-tag-product-dimension40]', 'product-dimension40');
    const dimension48 = this.getValue(product, '[data-tag-product-dimension48]', 'product-dimension48');
    const dimension152 = this.getValue(product, '[data-tag-product-dimension152]', 'product-dimension152');
    const dimension153 = this.getValue(product, '[data-tag-product-dimension153]', 'product-dimension153');
    const dimension154 = this.getValue(product, '[data-tag-product-dimension154]', 'product-dimension154');

    if (currency) {
      dataFromDom.currency = currency;
    }

    if (data.type === 'productClick' && list) {
      dataFromDom.list = list;
    }

    return clean({
      name,
      id,
      category,
      brand,
      price,
      variant,
      position: data.type !== 'productClick' && position,
      list: (data.type === 'productImpression' || data.type === 'productDetail') && list,
      dimension35,
      dimension36,
      dimension37,
      dimension38,
      dimension39,
      dimension40,
      dimension48,
      dimension152,
      dimension153,
      dimension154
    });
  },

  /**
   *
   * @param promotion
   */
  createDataForPromotion(promotion) {
    const name = this.getValue(promotion, '[data-tag-promotion-name]', 'promotion-name');
    const id = this.getValue(promotion, '[data-tag-promotion-id]', 'promotion-id');
    const creative = this.getValue(promotion, '[data-tag-promotion-creative]', 'promotion-creative');
    const position = this.getValue(promotion, '[data-tag-promotion-position]', 'promotion-position');

    return clean({
      name,
      id,
      creative,
      position
    });
  }
};
