<template>
  <div class="field field--radio" role="group" :aria-labelledby="`legend-${field.name || field.id}`">
    <div :id="`legend-${field.name || field.id}`" class="legend" v-html="legendHtml" />
    <ul class="field--radio">
      <li v-for="(option, i) in field.options"
          :key="i + '-option'">
        <input class="input is-sr-only"
               type="radio"
               :name="field.name"
               :id="option.id"
               :value="option.label"
               v-model.trim.lazy="radioValue">
        <span class="switch" />
        <label class="radio" :for="option.id">
          {{ option.label }}
        </label>
      </li>
    </ul>
    <p class="field--error"
       :id="field.idError"
       v-if="!!v && v.$error">
      <field-help v-for="(error, index) in field.errors"
                  :v="v"
                  :error="error"
                  :key="index" />
    </p>
    <p v-if="!!field.info" class="field--info">
      {{ field.info }}
    </p>
  </div>
</template>

<script>
  import FieldHelp from '@Feature/CustomForms/fields/FieldHelp/code/Scripts/components/field-help.vue';

  export default {
    components: { FieldHelp },

    props: {
      field: { type: Object, required: true, default: undefined },
      v: { type: Object, required: false, default: undefined },
      value: { type: String, required: true, default: undefined },
      legendTag: { type: String, required: false, default: undefined },
    },

    computed: {
      /**
       * Set propped value and emit on change to update parent value
       */
      radioValue: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        }
      },
      legendHtml() {
        return !!this.legendTag ? `<${this.legendTag}>${this.field.label}</${this.legendTag}>` : this.field.label;
      }
    },
  };
</script>
<style lang='scss' src="./field-radio.scss"></style>
