import { AnalyticsProductClickObjectException } from '../errors/analyticsError';
import Logger from '../utils/analyticsLogger';
import DataObject from './DataObject';
import ProductObject from './ProductObject';

export default class ProductClickObject extends DataObject {
  constructor(data) {
    super('productClick', data);

    this.category = 'Ecommerce';
    if (data.category) {
      Logger.warn(
        `[AnalyticsProductClickObjectException] Category always defaults to "Ecommerce" (L'Oréal guideline), currently [${
          data.category
        }] was provided`,
        data,
        false
      );
    }

    this.action = 'Product Click';
    if (data.action) {
      Logger.warn(
        `[AnalyticsProductClickObjectException] Action always defaults to "Product Click" (L'Oréal guideline), currently [${
          data.action
        }] was provided`,
        data,
        false
      );
    }

    this.label = 'Product Click';
    if (data.label) {
      this.label = data.label;
    }

    if (!data.products || data.products.length === 0) {
      Logger.error('[AnalyticsProductClickObjectException] Product is required for productClick', data);
      throw new AnalyticsProductClickObjectException('Product is required for productClick');
    }
  }

  initEcommerce() {
    this.result.ecommerce = this.result.ecommerce || {};
    this.result.ecommerce.click = this.result.ecommerce.click || {};
  }

  set category(category) {
    this.result.eventCategory = this.setProperty(category, false);
  }

  set action(action) {
    this.result.eventAction = this.setProperty(action, false);
  }

  set label(label) {
    this.result.eventLabel = this.setProperty(label, false);
  }

  set value(value) {
    this.result.eventValue = this.setProperty(value);
  }

  set products(products) {
    products = products.map(product => new ProductObject(product).toObject());

    this.initEcommerce();

    this.result.ecommerce.click.products = products;
  }

  get products() {
    return (this.result.ecommerce && this.result.ecommerce.click.products) || [];
  }

  set list(list) {
    this.initEcommerce();

    this.result.ecommerce.click.actionField = { list: this.setProperty(list) };
  }
}
