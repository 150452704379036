import { AnalyticsUserActionEventObjectException } from '../errors/analyticsError';
import Logger from '../utils/analyticsLogger';
import DataObject from './DataObject';

export default class UserActionEventObject extends DataObject {
  constructor(data) {
    super('uaevent', data);

    if (!data.category) {
      Logger.error("[AnalyticsUserActionEventObjectException] Category is required for uaevent (L'Oréal guideline)", data);
      throw new AnalyticsUserActionEventObjectException("Category is required for uaevent (L'Oréal guideline)");
    }

    if (!data.action) {
      Logger.error("[AnalyticsUserActionEventObjectException] Action is required for uaevent (L'Oréal guideline)", data);
      throw new AnalyticsUserActionEventObjectException("Action is required for uaevent (L'Oréal guideline)");
    }

    if (!data.label) {
      Logger.error("[AnalyticsUserActionEventObjectException] Label is required for uaevent (L'Oréal guideline)", data);
      throw new AnalyticsUserActionEventObjectException("Label is required for uaevent (L'Oréal guideline)");
    }

    this.result.ecommerce = undefined;
  }

  set category(category) {
    this.result.eventCategory = this.setProperty(category);
  }

  get category() {
    return this.result.eventCategory;
  }

  set action(action) {
    this.result.eventAction = this.setProperty(action);
  }

  get action() {
    return this.result.eventAction;
  }

  set label(label) {
    this.result.eventLabel = this.setProperty(label);
  }

  get label() {
    return this.result.eventLabel;
  }

  set value(value) {
    this.result.eventValue = this.setProperty(value);
  }
}
