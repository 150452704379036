<template>
  <div class="field field--checkbox">
    <input v-if="!!v"
           class="input is-sr-only"
           type="checkbox"
           :id="field.id"
           v-model.trim.lazy="checkboxValue"
           :aria-describedby="fieldDescribedby"
           :aria-required="v.required"
           :name="field.id"
           :required="v.required">
    <input v-else
           class="input is-sr-only"
           type="checkbox"
           :id="field.id"
           v-model.trim.lazy="checkboxValue"
           :aria-describedby="fieldDescribedby"
           :name="field.id">
    <span class="switch" />
    <label class="label"
           :for="field.id">
      <span v-html="htmlStr" />
    </label>
    <template v-if="!!v">
      <p class="field--error"
         :id="field.idError"
         v-if="v.$error">
        <field-help v-for="(error, index) in field.errors"
                    :v="v"
                    :error="error"
                    :key="index" />
      </p>
    </template>
    <p v-if="!!field.info" class="field--info">
      {{ field.info }}
    </p>
  </div>
</template>

<script>
  import FieldHelp from '@Feature/CustomForms/fields/FieldHelp/code/Scripts/components/field-help.vue';

  export default {
    components: { FieldHelp },

    props: {
      field: { type: Object, required: true, default: undefined },
      v: { type: Object, required: false, default: undefined },
      value: { type: Boolean, required: true, default: undefined },
    },

    computed: {
      /**
       * Set propped value and emit on change to update parent value
       */
      checkboxValue: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        }
      },
      htmlStr() {
        return this.allReplace(this.htmlDecode(this.field.label), { '&lt;': '<', '&gt;': '>' });
      },
      fieldDescribedby() {
        if (!!this.v) {
          return this.v.$error ? this.field.idError : false;
        }
        return false;
      },
    },

    methods: {
      /**
       * Decodes encoded html
       * @param {string} string - Encoded html
       * @returns {string}
       */
      allReplace(str, obj) {
        if (!!str) {
          for (const x in obj) {
            str = str.replace(new RegExp(x, 'g'), obj[x]);
          }
          return str;
        } else {
          return this.field.label;
        }
      },
      htmlDecode(string) {
        const e = document.createElement('div');
        e.innerHTML = string;
        return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
      },
    }
  };
</script>
<style lang='scss' src="./field-checkbox.scss"></style>
