<template>
  <div class="field field--select">
    <div class="control">
      <div
        class="select-custom"
        :class="{
          'disabled': isDisabled
      }">
        <template v-if="!!v && !customValidation">
          <div
            class="select-custom__btn"
            :class="{
              'has-error': v.$error
          }">
            <label
              :for="field.id"
              class="placeholder"
              :class="{
                'is-fixed': inputValue !== ''
              }"
            >{{ field.label }}</label>
            <select
              :aria-describedby="fieldDescribedby"
              :id="field.id"
              :name="field.id"
              v-model.trim.lazy="inputValue"
              @blur="v.$touch()"
              :disabled="isDisabled"
              :required="v.required">
              <option v-for="(option,index) in field.listValue" :key="index" :value="option" :selected="option === inputValue ? 'true' : 'false'">
                {{ option }}
              </option>
            </select>
            <svg width="16" height="8" aria-hidden="true">
              <path fill-rule="nonzero" d="M14.675.62l.65.76L8 7.659.675 1.379l.65-.759L8 6.341z"/>
            </svg>
          </div>
        </template>

        <template v-else-if="customValidation">
          <div
            class="select-custom__btn"
            :class="{
              'has-error': v.error
          }">
            <label
              :for="field.id"
              class="placeholder"
              :class="{
                'is-fixed': inputValue !== ''
              }"
            >{{ field.label }}</label>
            <select
              :aria-describedby="fieldDescribedby"
              :id="field.id"
              :name="field.id"
              v-model.trim.lazy="inputValue"
              :disabled="isDisabled"
              :required="v.required">
              <option v-for="(option,index) in field.listValue" :key="index" :value="option" :selected="option === inputValue ? 'true' : 'false'">
                {{ option }}
              </option>
            </select>
            <svg width="16" height="8" aria-hidden="true">
              <path fill-rule="nonzero" d="M14.675.62l.65.76L8 7.659.675 1.379l.65-.759L8 6.341z"/>
            </svg>
          </div>
        </template>

        <template v-else>
          <div
            class="select-custom__btn">
            <label
              :for="field.id"
              class="placeholder"
              :class="{
                'is-fixed': inputValue !== ''
              }"
            >{{ field.label }}</label>
            <select
              :id="field.id"
              :name="field.id"
              :disabled="isDisabled"
              v-model.trim.lazy="inputValue">
              <option v-for="(option,index) in field.listValue" :key="index" :value="option" :selected="option === inputValue ? 'true' : 'false'">
                {{ option }}
              </option>
            </select>
            <svg width="16" height="8" aria-hidden="true">
              <path fill-rule="nonzero" d="M14.675.62l.65.76L8 7.659.675 1.379l.65-.759L8 6.341z"/>
            </svg>
          </div>
        </template>
      </div>
    </div>

    <template v-if="!!v">
      <p class="field--error"
         :id="field.idError"
         v-if="v.$error">
        <field-help
          v-for="(error, index) in field.errors"
          :v="v"
          :error="error"
          :key="index"/>
      </p>
      <p class="field--error"
         :id="field.idError"
         v-if="v.error">
        <field-help
          v-for="(error, index) in field.errors"
          :v="v"
          :error="error"
          :key="index"/>
      </p>
    </template>
  </div>
</template>

<script>
  import { clickoutside } from '@Foundation/directives/clickoutside/clickoutside';
  import FieldHelp from '@Feature/CustomForms/fields/FieldHelp/code/Scripts/components/field-help.vue';

  export default {
    name: 'field-select',

    directives: {
      clickoutside,
    },

    components: {
      FieldHelp
    },

    props: {
      isDisabled: { type: Boolean, required: false, default: false },
      field: { type: Object, required: true, default: undefined },
      v: { type: Object, required: false, default: undefined },
      value: { type: [String, Number], required: true, default: undefined },
      customValidation: { type: Boolean, required: false, default: false },
      startUpdate: { type: Boolean, required: false, default: false },
    },

    computed: {
      /**
       * Set propped value and emit on change to update parent value
       */
      inputValue: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        }
      },
      fieldDescribedby() {
        if (this.v.$error || this.v.error) {
          return this.field.idError;
        }
        return false;
      }
    },

    watch: {
      startUpdate(value) {
        value ? this.$forceUpdate() : false;
      }
    }
  };
</script>
<style lang='scss' src="./field-select.scss"></style>
