<template>
  <span
    class="field-help"
    v-if="v.$error && !v[error.validator]">
    {{ error.message }}
  </span>
</template>

<script>
  export default {
    props: {
      error: { type: Object, required: true, default: undefined },
      v: { type: Object, required: true, default: undefined },
    },
  };
</script>
<style lang='scss' src="./field-help.scss"></style>

