<template>
  <div class="field field--input">
    <div class="control">
      <label
        class="label"
        :class="{ 'is-fixed': inputValue !== '' || fixedLabel }"
        :for="fieldData.id">
        {{ mandatoryValue ? fieldData.label.substring(0, fieldData.label.length - 1) : fieldData.label }}
      </label>
      <input
        v-if="!!v"
        class="input"
        :class="{
          'has-icon': isPassword,
          'has-error': v.$error
        }"
        :id="fieldData.id"
        @blur="v.$touch(), focused(false)"
        @focus="focused(true)"
        @input="validate($event.target.value)"
        v-model.trim="inputValue"
        :type="type"
        :aria-describedby="fieldDescribedby"
        :name="fieldData.id"
        :required="v.required">
      <input
        v-else
        class="input"
        :class="{
          'has-icon': isPassword
        }"
        @blur="focused(false)"
        @focus="focused(true)"
        :id="fieldData.id"
        v-model.trim="inputValue"
        :type="type"
        :name="fieldData.id">
      <button
        type="button"
        :aria-pressed="`${ passwordIsRevealed ? 'true' : 'false' }`"
        class="input-icon input-icon--password"
        :class="{ 'is-active': passwordIsRevealed }"
        @click="toggleRevealPassword"
        v-if="isPassword">
        <svg viewbox="0 0 23 14" aria-hidden="true">
          <path d="M11.5 0c5.227 0 9.691 2.903 11.5 7-1.809 4.097-6.273 7-11.5 7S1.809 11.097 0 7c1.809-4.097 6.273-7 11.5-7zm.5 11c2.208 0 4-1.792 4-4s-1.792-4-4-4-4 1.792-4 4 1.792 4 4 4zm0-6c1.107 0 2 .893 2 2s-.893 2-2 2-2-.893-2-2 .893-2 2-2z" fill-rule="nonzero"/>
        </svg>
        <span class="is-sr-only">{{ fieldData.passwordIconInfo }}</span>
      </button>
    </div>

    <p class="field--error"
       :id="fieldData.idError"
       v-if="!!v && v.$error">
      <field-help
        v-for="(error, index) in fieldData.errors"
        :v="v"
        :error="error"
        :key="index"/>
    </p>
    <p v-if="!!fieldData.info" class="field--info" :id="`${fieldData.id}-help`">
      {{ fieldData.info }}
    </p>
  </div>
</template>

<script>
  import FieldHelp from '@Feature/CustomForms/fields/FieldHelp/code/Scripts/components/field-help.vue';

  export default {
    components: { FieldHelp },

    props: {
      field: { type: Object, required: true, default: undefined },
      v: { type: Object, required: false, default: undefined },
      value: { type: String, required: true, default: undefined },
      liveValidation: { type: Boolean, required: false, default: false },
      mandatoryValue: { type: Boolean, required: false, default: false },
      startUpdate: { type: Boolean, required: false, default: false }
    },

    data() {
      return {
        isPassword: false,
        passwordIsRevealed: false,
        type: this.field.type === 'base-id' ? 'password' : this.field.type,
        fixedLabel: false,
        fieldData: this.field
      };
    },

    computed: {
      /**
       * Set propped value and emit on change to update parent value
       */
      inputValue: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        }
      },
      fieldDescribedby() {
        if (this.v.$error) {
          return `${this.fieldData.id}-help ${this.fieldData.idError}`;
        }
        return `${this.fieldData.id}-help`;
      },
    },

    watch: {
      startUpdate(value) {
        value && this.updateField();
      }
    },

    mounted() {
      this.isPassword = (this.type === 'password') ? true : false;
    },

    methods: {
      updateField() {
        this.$forceUpdate();
      },
      focused(val) {
        this.fixedLabel = val;
      },
      validate(value) {
        /* istanbul ignore else */
        if (this.liveValidation) {
          this.v.$touch();
          this.inputValue = value;
        }
      },

      /**
       * Toggle a passowrd field to a text foeld
       * @param {object} e - Click event
       * @returns {undefined}
       */
      toggleRevealPassword() {
        this.type = (this.type === 'password') ? 'text' : 'password';
        this.passwordIsRevealed = !this.passwordIsRevealed;
      },
    }
  };
</script>

<style lang='scss' src="./field-input.scss"></style>
